@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #181818;
    font-family: 'Roboto', sans-serif;
}

.f-v {
    @apply flex flex-col;
}

.f-h {
    @apply flex flex-row;
}

.form-label {
    @apply text-sm text-zinc-500;
}

.form-input {
    @apply px-2 py-1 h-[28px] rounded-md text-sm bg-[#181818] text-white;
}

.form-input:disabled {
    @apply text-white bg-[#1F1E1F] border border-[#2A282B];
}

.form-select {
    @apply form-input;
    /* border-right: 4px solid transparent; */
}

.form-textarea {
    @apply p-2 rounded-md text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.form-textarea:disabled {
    @apply p-2 rounded-md text-sm bg-[#1F1E1F] text-white border border-[#2A282B];
}

.form-input-table-row {
    @apply bg-[#181818] text-white;
}

.form-input-table-row:disabled {
    @apply text-white bg-[#1F1E1F] border border-[#2A282B];
}

.form-textarea-table-row {
    @apply bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF];
}

.form-textarea-table-row:disabled {
    @apply bg-[#1F1E1F] text-white border border-[#2A282B];
}

.button {
    @apply px-4 py-1 min-w-[120px] rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365];

    /* &:disabled {
        @apply text-zinc-400;
    } */
}

/*расчет 3 строк в названии позиции*/
.fourrow {
    margin: 0;
    /* Убираем внешний отступ */
    -webkit-line-clamp: 3;
    /* Число отображаемых строк */
    display: -webkit-box;
    /* Включаем флексбоксы */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация */
    overflow: hidden;
    /* Обрезаем всё за пределами блока */
}

/*расчет 2 строк в названии позиции*/
.tworow {
    margin: 0;
    /* Убираем внешний отступ */
    -webkit-line-clamp: 2;
    /* Число отображаемых строк */
    display: -webkit-box;
    /* Включаем флексбоксы */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация */
    overflow: hidden;
    /* Обрезаем всё за пределами блока */
}

/*расчет 1 строк в названии позиции*/
.onerow {
    margin: 0;
    /* Убираем внешний отступ */
    -webkit-line-clamp: 1;
    /* Число отображаемых строк */
    display: -webkit-box;
    /* Включаем флексбоксы */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация */
    overflow: hidden;
    /* Обрезаем всё за пределами блока */
}

.switch {
    width: 43px;
    height: 25px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: solid 2px #33303300;
}

.switch-on {
    background-color: #187a33;
    color: #187a33;
}

.switch-off {
    background-color: #656365;
    color: #656365;
}

.switch-disabled {
    background-color: #242225;
    border: solid 2px #333033;
    cursor: default;
}

.switch-toggle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.toggle-on {
    transform: translateX(13px);
}

.toggle-off {
    transform: translateX(-1px);
}

.toggle-on-disabled {
    background-color: #187a33;
}

.toggle-off-disabled {
    background-color: #8a8a8a;
}

.custom-scrollbar {
    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .1);
        border-radius: 3px;

        &:hover {
            background: rgba(255, 255, 255, .2);
        }
    }
}